import React, { useState } from "react";
import { Route, Routes} from "react-router-dom";
import { Login } from "./components/login/login";
import { RequireAuth } from "react-auth-kit";
import StockManagementPortal from "./components/stockmanagement/stockmanagementportal";
import Home from "./components/home/home"
import { TabMenu } from 'primereact/tabmenu';
import { useNavigate } from "react-router-dom";
import { Outlet } from 'react-router';
import { useSignOut } from "react-auth-kit";
import ZAbschlagManagementPortal from "./components/zabschlagmanagement/zabschlagmanagement";

function NavBar() {
    const [activeIndex, setActiveIndex] = useState(0);
    const navigate = useNavigate();
    const signOut = useSignOut();
    const logout =() =>{
        signOut();
        navigate("/");

    }
    
    const items = [
        {label: 'Home', icon: 'pi pi-fw pi-home', command: () => {navigate('/home') }},
        {label: 'Stock Management', icon: 'pi pi-fw pi-calendar', command: () => {navigate('/stockmanagement') }},
        {label: 'ZAbschlag Management', icon: 'pi pi-fw pi-calendar', command: () => {navigate('/zabschlagmanagement') }},
        {label: 'Logout', icon: 'pi pi-fw pi-cog', command: (event) => {logout(event)}}
    ];

    return (
        <div className="card">
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        </div>
    )
}

function App() {
    return (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            element={
              <>
                <NavBar />
                <Outlet />
              </>
            }
          >
            <Route path="/home" element={
                    <RequireAuth loginPath="/">
                        <Home />
                    </RequireAuth>
      }></Route>
            <Route path="/stockmanagement"  element={
                    <RequireAuth loginPath="/">
                        <StockManagementPortal />
                    </RequireAuth>
      }></Route>
       <Route path="/zabschlagmanagement"  element={
                    <RequireAuth loginPath="/">
                        <ZAbschlagManagementPortal />
                    </RequireAuth>
      }></Route>
          </Route>
        </Routes>
    );
  }

export default App;
