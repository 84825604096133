import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios'
import { Tag } from 'primereact/tag';
import moment from 'moment'
import { FileUpload } from 'primereact/fileupload';


function StockManagementPortal() {
    let emptyItem = {
        idLink: null,
        orderDate: '',
        deliveryDate: '',
        distributorName: '',
        amount: 0.0,
        description: '',
        invoiceDate: '',
        paidDate:'',
        file:'',
        status: 0,
    };
    
    async function getData()
    {
        const res = await axios.get('https://app_sm-1-q3847552.deta.app/getstockincomings')
        setProducts(res.data.result.stock_incomings)
        return res.data.result.stock_incomings
    }

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyItem);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        // getProducts().then(data => setProducts(data));
        getData()
    }, []);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'EUR' });
    }
    const statusOptions = [
        { name: 'Open', value: 0},
        { name: 'Closed', value: 1},
       
    ];


    const openNew = () => {
        setProduct(emptyItem);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    async function createStockIncoming(product, file) {
        var formData = new FormData();
        formData.append("data", product);
        if (typeof file !== "string")
        {
            formData.append("file", file);
        }
        
        axios({
                method: 'post',
                url: 'https://app_sm-1-q3847552.deta.app/stockincomings',
                data: formData,
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
    }

    function updateStockIncoming(product, id, file) {
        var formData = new FormData();
        formData.append("data", product);
        formData.append("id", id);
        if (typeof file !== "string")
        {
            formData.append("file", file);
        }
        
        axios({
                method: 'PUT',
                url: `https://app_sm-1-q3847552.deta.app/updatestockincomings/${id}`,
                data: formData,
                // headers: {
                //     'Content-Type': 'application/json',
                // },
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
    }
    const invoiceUploadHandler = ({files}) => {
        const [file] = files;
        uploadInvoice(file)
    };
    
    const uploadInvoice = async (file) => {
        product.file = file
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
        // const headers={'Content-Type': file.type}
        // const response = await axios.post("http://127.0.0.1:8000/upload", formData)
        // console.log(response)
    };

    const saveProduct = () => {
        setSubmitted(true);
        if (product.distributorName.trim()) {
            // let _products = [...products];
            let _product = {...product};
            if (product.id) {
                if (typeof _product.orderDate !== "string")
                {
                    _product.orderDate = moment(_product.orderDate).format('YYYY-MM-DD');
                }
                if (typeof _product.deliveryDate!== "string")
                {
                    _product.deliveryDate =  moment(_product.deliveryDate).format('YYYY-MM-DD');
                }
                if (typeof _product.invoiceDate!== "string")
                {
                    _product.invoiceDate =  moment(_product.invoiceDate).format('YYYY-MM-DD');
                }
                if(typeof _product.paidDate!== "string")
                {
                    _product.paidDate = moment(_product.paidDate).format('YYYY-MM-DD');
                }
                // if (!_product.file)
                // {
                //     _product.file = '';
                // }
                updateStockIncoming(JSON.stringify(_product), _product.id, _product.file)
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Entry Updated', life: 3000 });
            }
            else { 
                _product.idLink = createId();
                _product.orderDate = moment(_product.orderDate).format('YYYY-MM-DD');
                if (_product.deliveryDate)
                {
                _product.deliveryDate =  moment(_product.deliveryDate).format('YYYY-MM-DD');
                }
                if (_product.invoiceDate)
                {
                    _product.invoiceDate =  moment(_product.invoiceDate).format('YYYY-MM-DD');
                }
                if(_product.paidDate)
                {
                    _product.paidDate = moment(_product.paidDate).format('YYYY-MM-DD');
                }
                createStockIncoming(JSON.stringify(_product), product.file)
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Entry Created', life: 3000 });
                
            }
            getData()
            // setProducts(_products);
            setProductDialog(false);
            setProduct(emptyItem);
        }
    }

    const editProduct = (product) => {
        if (product.status === "Open")
        {
            product.status = 0
        }
        if(product.status === "Closed")
        {
            product.status = 1
        }
        setProduct({...product});
        setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    }

    const deleteProduct = () => {
        // let _products = products.filter(val => val.id !== product.id);
        let id = product.id
        axios.delete(`https://app_sm-1-q3847552.deta.app/deletestockincoming/${id}`).then(response => {
            console.log(response);
        })
        getData()
        // setProducts(_products);
        setDeleteProductDialog(false);
        setProduct(emptyItem);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Entry Deleted', life: 3000 });
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const deleteSelectedProducts = () => {
        let _products = products.filter(val => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    }

    const onStatusChange = (e) => {
        let _product = {...product};
        _product['status'] = e.value
        setProduct(_product);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = {...product};
        _product[`${name}`] = val;
        setProduct(_product);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _product = {...product};
        _product[`${name}`] = val;
       

        setProduct(_product);
    }

    const onDateChange = (e, name) => {
        let _product = {...product};
        _product[`${name}`] = e.value;
        setProduct(_product);
    }

    const priceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.amount);
    }

    const statusDisplay = (rowData) => {
        if (rowData.status === 0)
        {
            rowData.status = "Open";
        }
        if (rowData.status === 1)
        {
            rowData.status = "Closed";
        }
        return <Tag value={rowData.status} severity={getSeverity(rowData)}></Tag>;
    }

    const uploadedFileDisplay = (rowData) => {
        if (rowData.file)
        {
            return <Button label="View" link onClick={()=> window.open("http://admin.srivayuputra.de/content/stocks/" + rowData.file, "_blank")}/>
        }
        
    }

    const getSeverity = (rowData) => {
        switch (rowData.status) {
            case 'Closed':
                return 'success';

            // case 'LOWSTOCK':
            //     return 'warning';

            case 'Open':
                return 'danger';

            default:
                return null;
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteProduct(rowData)} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
            <span className="p-input-icon-left w-full md:w-auto">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." className="w-full lg:w-auto" />
            </span>
            <div className="mt-3 md:mt-0 flex justify-content-end">
                <Button icon="pi pi-plus" className="mr-2 p-button-rounded" onClick={openNew} tooltip="New" tooltipOptions={{position: 'bottom'}} />
                <Button icon="pi pi-trash" className="p-button-danger mr-2 p-button-rounded" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} tooltip="Delete" tooltipOptions={{position: 'bottom'}} />
                <Button icon="pi pi-upload" className="p-button-help p-button-rounded" onClick={exportCSV} tooltip="Export" tooltipOptions={{position: 'bottom'}} />
            </div>
        </div>
    );
    const productDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
        </React.Fragment>
    );

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );

    const deleteProductsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </React.Fragment>
    );

   

    return (
        <div>
        <div className="datatable-crud-demo surface-card p-4 border-round shadow-2">
            <Toast ref={toast} />

            <div className="text-3xl text-800 font-bold mb-4">Stock Management Portal</div>

            <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                globalFilter={globalFilter} header={header} responsiveLayout="scroll">
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
                <Column field="orderDate" header="Order Date" sortable style={{ minWidth: '12rem' }}></Column>
                <Column field="deliveryDate" header="Delivery Date" sortable style={{ minWidth: '16rem' }}></Column>
                <Column field="distributorName" header="Distributor Name" sortable style={{ minWidth: '12rem' }}></Column>
                <Column field="amount" header="Amount" body={priceBodyTemplate} sortable style={{ minWidth: '8rem' }}></Column>
                <Column field="description" header="Description" sortable style={{ minWidth: '10rem' }}></Column>
                <Column field="invoiceDate" header="Invoice Date" sortable style={{ minWidth: '12rem' }}></Column>
                <Column field="paidDate" header="Paid Date" sortable style={{ minWidth: '12rem' }}></Column>
                <Column field="status" header="Status" body={statusDisplay} sortable style={{ minWidth: '12rem' }}></Column>
                <Column field="file" header="Invoice File" body={uploadedFileDisplay} sortable style={{ minWidth: '12rem' }}></Column>

                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
            </DataTable>
  
            <Dialog visible={productDialog} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '40vw'}} header="Stock Details" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                {product.image && <img src={`demo/images/product/${product.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.image} className="block mt-0 mx-auto mb-5 w-20rem shadow-2" />}
                <div className="field">
                    <div className="formgrid grid">
                        <div className="field col-6">
                            <label htmlFor="orderDate">Order Date</label>
                            <Calendar inputId="orderDate" value={product.orderDate} onChange={(e) => onDateChange(e, 'orderDate')} showIcon required autoFocus className={classNames({ 'p-invalid': submitted && !product.orderDate })}  />
                            {submitted && !product.orderDate && <small className="p-error">Order Date is required.</small>}
                          
                        </div>
                        <div className="field col-6">
                            <label htmlFor="deliveryDate">Delivery Date</label>
                            <Calendar id="deliveryDate" value={product.deliveryDate} onChange={(e) => onDateChange(e, 'deliveryDate')} showIcon required autoFocus />
                            {/* {submitted && !product.deliveryDate && <small className="p-error">Delivery Date is required.</small>} */}
                        </div>
                    </div>
                </div>

                <div className="field">
                    <div className="formgrid grid">
                        <div className="field col-6">
                            <label htmlFor="distributorName">Distributor Name</label>
                            <InputText id="distributorName" value={product.distributorName} onChange={(e) => onInputChange(e, 'distributorName')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.distributorName })} />
                            {submitted && !product.distributorName && <small className="p-error">Distributor is required.</small>}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="amount">Amount</label>
                            <InputNumber id="amount" value={product.amount} onValueChange={(e) => onInputNumberChange(e, 'amount')} mode="currency" currency="EUR" locale="en-US" />
                        </div>
                    </div>
                </div>

                <div className="field">
                    <label htmlFor="description">Description</label>
                    <InputTextarea id="description" value={product.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
                </div>

                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="invoiceDate">Invoice Date</label>
                        <Calendar id="invoiceDate" value={product.invoiceDate} onChange={(e) => onInputChange(e, 'invoiceDate')} showIcon />
                    </div>
                    <div className="field col">
                        <label htmlFor="paidDate">Paid Date</label>
                        <Calendar value={product.paidDate} onChange={(e) => onInputChange(e, 'paidDate')} showIcon />
                    </div>
                </div>
                <div className="field">
                <label htmlFor="uploadFiles">Upload Files</label>
                <FileUpload name="invoice"
    customUpload={true}
    uploadHandler={invoiceUploadHandler}
    mode="basic"
    chooseLabel="Upload invoice"/>
                </div>
                <div className="field">
                <div className="field col">
                    <label htmlFor="status">Status</label>
                    <Dropdown id="status" value={product.status} onChange={onStatusChange} options={statusOptions} optionLabel="name"
          optionValue="value" placeholder="Select a Status" className="w-full md:w-14rem" />
                </div>
                </div>
            </Dialog>

            <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {product && <span>Are you sure you want to delete <b>{product.name}</b>?</span>}
                </div>
            </Dialog>

            <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {product && <span>Are you sure you want to delete the selected Entries?</span>}
                </div>
            </Dialog>
        </div>
        </div>
    );
}

export default StockManagementPortal;