import React, { useState } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { useSignIn } from "react-auth-kit";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

function Login() {
    const [showMessage, setShowMessage] = useState(false);
    
    const [error, setError] = useState("");
    const signIn = useSignIn();
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        console.log("Values: ", values);
        setError("");
        
        try {
        const response = await axios.post(
            "https://app_sm-1-q3847552.deta.app/login",
            values
        );

        signIn({
            token: response.data.token,
            expiresIn: 20,
            tokenType: "Bearer",
            authState: { username: values.username },
        });
        navigate("/home");
        } catch (err) {
        if (err && err instanceof AxiosError)
            setError(err.response?.data.message);
        else if (err && err instanceof Error) setError(err.message);

        console.log("Error: ", err);
        console.log("Error: ", error);
        setShowMessage(true);

        formik.resetForm();
        }
    };

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validate: (data) => {
            let errors = {};

            if (!data.username) {
                errors.username = 'Username is required.';
            }
            // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
            //     errors.email = 'Invalid email address. E.g. example@email.com';
            // }

            if (!data.password) {
                errors.password = 'Password is required.';
            }

            return errors;
        },
        // onSubmit: (data) => {
        //     onSubmit
        //     setFormData(data);
        //     setShowMessage(true);

        //     formik.resetForm();
        // }
        onSubmit
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dialogFooter = <div className="text-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;

    return (
        <div>
            
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="flex align-items-center flex-column pt-6 px-3">
                    <i className="pi pi-times-circle" style={{ fontSize: '5rem', color: 'var(--red-500)' }}></i>
                    <div className="text-900 font-bold text-xl mt-5">Login not Successful!</div>
                    <p className="line-height-3 my-4">
                        Check your Login Details and try again..
                    </p>
                </div>
            </Dialog>
            
            <div className="App">
            <div className="flex align-items-center flex-column pt-6 px-3">
            <div className="surface-card border-round shadow-2 p-4">
                <span className="text-900 text-2xl font-medium mb-4 block">Login</span>
        
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="mb-5">
                        <span className="p-float-label p-input-icon-right">
                            <i className="pi pi-envelope" />
                            <InputText id="username" name="username" value={formik.values.username} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('username') })} />
                            <label htmlFor="username" className={classNames({ 'p-error': isFormFieldValid('username') })}>Username*</label>
                        </span>
                        {getFormErrorMessage('username')}
                    </div>
                    <div className="mb-5">
                        <span className="p-float-label">
                            <Password id="password" name="password" value={formik.values.password} onChange={formik.handleChange} toggleMask
                                className={classNames({ 'p-invalid': isFormFieldValid('password') })} />
                            <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid('password') })}>Password*</label>
                        </span>
                        {getFormErrorMessage('password')}
                    </div>
                    <Button type="submit" label="Submit" />
                </form>
            </div>
            </div>
            </div>
            </div>
        
       
    );
}

export {Login};